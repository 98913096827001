import { useState } from "react";
import styles from "./share.module.scss";

type TShareProps = {
  shareText: string;
  subject: string;
  className?: string;
};

const Share = ({ shareText, subject, className }: TShareProps) => {
  const windowLocation = window.location.href;
  const [isCopied, setIsCopied] = useState(false);

  const handleShareClick = () => {
    setIsCopied(true);
    navigator?.clipboard?.writeText(windowLocation);
    setTimeout(() => setIsCopied(false), 1000);
  };

  return (
    <a
      href={`mailto:?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(windowLocation)}`}
      className={`${styles.share} ${isCopied ? styles.copied : ""} ${
        className ? className : ""
      }`}
      title="URL Copied!"
      onClick={handleShareClick}
    >
      <svg
        className={styles.share__icon}
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.25 10.5137C11.8255 10.5137 12.3125 10.724 12.7109 11.1445C13.1094 11.543 13.3086 12.0299 13.3086 12.6055C13.3086 13.1589 13.1094 13.6348 12.7109 14.0332C12.3125 14.4538 11.8255 14.6641 11.25 14.6641C10.6745 14.6641 10.1875 14.4538 9.78906 14.0332C9.39062 13.6348 9.19141 13.1478 9.19141 12.5723C9.19141 12.4395 9.20247 12.2845 9.22461 12.1074L4.21094 9.18555C3.79036 9.56185 3.30339 9.75 2.75 9.75C2.15234 9.75 1.64323 9.55078 1.22266 9.15234C0.824219 8.73177 0.625 8.22266 0.625 7.625C0.625 7.02734 0.824219 6.5293 1.22266 6.13086C1.64323 5.71029 2.15234 5.5 2.75 5.5C3.30339 5.5 3.79036 5.68815 4.21094 6.06445L9.19141 3.17578C9.14714 2.97656 9.125 2.81055 9.125 2.67773C9.125 2.08008 9.32422 1.58203 9.72266 1.18359C10.1432 0.763021 10.6523 0.552734 11.25 0.552734C11.8477 0.552734 12.3457 0.763021 12.7441 1.18359C13.1647 1.58203 13.375 2.08008 13.375 2.67773C13.375 3.25326 13.1647 3.7513 12.7441 4.17188C12.3457 4.59245 11.8477 4.80273 11.25 4.80273C10.6966 4.80273 10.2096 4.60352 9.78906 4.20508L4.80859 7.12695C4.85286 7.32617 4.875 7.49219 4.875 7.625C4.875 7.75781 4.85286 7.92383 4.80859 8.12305L9.85547 11.0449C10.2539 10.6908 10.7188 10.5137 11.25 10.5137Z"
          fill="#5A5A5A"
        />
      </svg>
      {shareText}
    </a>
  );
};

export default Share;
