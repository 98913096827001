import { useMemo } from "react";
import IconComponent from "shared/IconComponent";
import { IconName } from "shared/IconComponent/IconComponent";

type TPaginationNumbers = {
  activePage: number;
  noOfPages: number;
  onChange: (page: number) => void;
};

function PaginationNumbers({
  activePage,
  noOfPages,
  onChange,
}: TPaginationNumbers) {
  const isArrowLeftDisabled = activePage === 1;
  const isArrowRightDisabled = activePage === noOfPages;
  const areDotsDisplayed = noOfPages > 7;

  const renderDots = (dots: number[]) =>
    dots.map((key) => {
      const pageNumber = key + 1;
      const isActive = activePage === pageNumber;

      return (
        <span
          onClick={() => !isActive && onChange(pageNumber)}
          className={`m-pagination__numbers-page ${
            isActive ? "m-pagination__numbers-page--active" : ""
          }`}
          key={`pagination-item-${key}`}
        >
          {pageNumber}
        </span>
      );
    });

  const pages = useMemo(() => Array.from(Array(noOfPages).keys()), [noOfPages]);

  return (
    <div className="m-pagination__numbers">
      <span
        className={`m-pagination__numbers-arrow ${
          isArrowLeftDisabled ? "m-pagination__numbers-arrow--disabled" : ""
        }`}
        onClick={() => !isArrowLeftDisabled && onChange(activePage - 1)}
      >
        <IconComponent name={IconName.LeftArrow} />
      </span>
      {!areDotsDisplayed && renderDots(pages)}
      {areDotsDisplayed && (
        <>
          {renderDots(
            pages.slice(0, activePage > 3 ? 1 : Math.max(activePage + 1, 3))
          )}
          <span className="m-pagination__numbers-dots">...</span>
          {activePage > 3 && activePage < noOfPages - 2 && (
            <>
              {renderDots([activePage - 2, activePage - 1, activePage])}
              <span className="m-pagination__numbers-dots">...</span>
            </>
          )}
          {renderDots(
            pages.slice(
              activePage < noOfPages - 2
                ? noOfPages - 1
                : Math.min(activePage - 2, noOfPages - 3),
              noOfPages
            )
          )}
        </>
      )}
      <span
        className={`m-pagination__numbers-arrow ${
          isArrowRightDisabled ? "m-pagination__numbers-arrow--disabled" : ""
        }`}
        onClick={() => !isArrowRightDisabled && onChange(activePage + 1)}
      >
        <IconComponent name={IconName.RightArrow} />
      </span>
    </div>
  );
}

export default PaginationNumbers;
