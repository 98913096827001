import { Suspense } from "react";
import { getDOMComponentType } from "helpers";
import { Loader } from "shared";
import { COMPONENT_MAP, Components } from "./constants";
import "./index.scss";

function App({ element: rootElement }: { element: HTMLElement }) {
  // const rootElement = document.getElementById(DOM_ELEMENT_ID);
  const componentType = getDOMComponentType(rootElement);
  const Component = componentType
    ? COMPONENT_MAP[componentType as Components]
    : null;

  return Component && rootElement ? (
    <Suspense fallback={<Loader />}>
      <Component rootElement={rootElement} />
    </Suspense>
  ) : null;
}

export default App;
