import {
  createContext,
  useContext,
  ReactNode,
  FC,
} from "react";
import { STYLES } from "../../constants";

interface StyleContextType {
  style: STYLES;
}

const defaultContext = {
  style: STYLES.KP,
};

export const StyleContext = createContext<StyleContextType | undefined>(defaultContext);

export const useStyleContext = (): StyleContextType => {
  const context = useContext(StyleContext);
  if (!context) {
    console.error("useStyleContext must be used within a TabsProvider");

    return defaultContext
  }
  return context;
};

interface StyleProviderProps {
  children: ReactNode;
  style?: STYLES;
}

export const StyleProvider: FC<StyleProviderProps> = ({style = STYLES.KP, children}) => {
  return (
    <StyleContext.Provider
      value={{
        style,
      }}
    >
      {children}
    </StyleContext.Provider>
  );
};
