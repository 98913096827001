import { HTMLProps, Ref, forwardRef } from "react";
import { Icon } from "shared";
import { IconName } from "shared/IconComponent/IconComponent";

type TCustomInputProps = HTMLProps<HTMLInputElement> & {
  variant: "insideLabel" | "outsideLabel";
  label: string;
  iconName?: IconName
};

type TCustomInputRef = Ref<HTMLInputElement>;

const CustomInput = forwardRef(
  (props: TCustomInputProps, ref: TCustomInputRef) => {
    return (
      <div className="m-date-picker__wrapper">
        {props.variant === "outsideLabel" && props.label && (
          <label className="m-date-picker__label">{props.label}</label>
        )}
        <input
          onClick={(ev) => {
            props?.onClick?.(ev);
            ev.preventDefault();
          }}
          onChange={() => {}}
          ref={ref}
          value={props.value}
          placeholder={props.placeholder}
          className={`form-control m-date-picker__input`}
        />
        <Icon
          name={
            props.iconName || (
              props.variant === "insideLabel"
              ? IconName.DownArrow
              : IconName.Calendar
            )
          }
        />
      </div>
    );
  }
);

export default CustomInput;
