import { IFilterOption } from "components/Blog/Blog.types";

export const getDOMComponentType = (element: HTMLElement | null) =>
  element?.getAttribute("data-component");

export const getDOMComponentBaseName = (element: HTMLElement | null) =>
  element?.getAttribute("data-basename") ?? undefined;

export const getDOMComponentDomain = (element: HTMLElement | null) =>
  element?.getAttribute("data-api-domain") ||
  process.env.REACT_APP_API_DOMAIN ||
  "";

export const getDOMComponentDomainPath = (element: HTMLElement | null) =>
  element?.getAttribute("data-domain-path") || "";

export const getDOMComponentLabels = (element: HTMLElement | null): string =>
  element?.getAttribute("data-labels") || "";

export const updateCanonicalLink = (value: string) => {
  const canonicalElement = document?.querySelector('link[rel="canonical"]');

  if (canonicalElement) {
    (canonicalElement as HTMLAnchorElement).href = value;
  }
};

export const updateDocumentTitle = (title: string | undefined) => {
  const sessionTitle = sessionStorage.getItem("page-title");

  if (!sessionTitle) {
    sessionStorage.setItem("page-title", document.title);
  }

  const newTitle = title || sessionStorage.getItem("page-title");

  if (newTitle) {
    document.title = newTitle;
  }
};

export const updateBreadcrumbs = (category: IFilterOption | undefined) => {
  const element = document.getElementsByClassName("breadcrumb-holder")?.[0];
  const list = element?.children?.[0];

  const breadcrumbElement = document.createElement("li");

  const linkElement = document.createElement("a");
  linkElement.className = "text-uppercase";
  linkElement.setAttribute("href", category?.Title || "");
  linkElement.appendChild(document.createTextNode(category?.Title || "All"));
  breadcrumbElement.appendChild(linkElement);

  if (list) {
    if (list.lastChild) {
      list.removeChild(list.lastChild);
    }
    list.appendChild(breadcrumbElement);
  }
};

export const updateHeaderBasketCount = (count?: number) => {
  const element = document.getElementById("basketCount");

  if (element) {
    element.textContent = count ? `(${count})` : "";
  }
};
