import ReactDOM from "react-dom/client";
import "./index.scss";
import "./App.scss";
import App from "./App";
import { DOM_ELEMENT_CLASS } from "./constants";

const containerById = document.getElementById(DOM_ELEMENT_CLASS);

if (containerById) {
  // keep this for main KP
  const root = ReactDOM.createRoot(containerById);

  root.render(<App element={containerById as HTMLElement} />);
} else {
  const containers = document.getElementsByClassName(DOM_ELEMENT_CLASS);

  if(containers) {
    for(let i=0; i< containers.length; i++) {
      const container = containers.item(i);
  
      if (container) {
        const root = ReactDOM.createRoot(container);
      
        root.render(<App element={container as HTMLElement} />);
      }
    }
  }
}


