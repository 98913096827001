import { useEffect } from "react";
import { Button, Icon } from "shared";
import { IconName } from "shared/IconComponent/IconComponent";

type TModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  closeLabel?: string;
};

function Modal({ isOpen, onClose, title, children, closeLabel }: TModalProps) {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("is-fixed");
    }

    return () => document.body.classList.remove("is-fixed");
  }, [isOpen]);

  return isOpen ? (
    <div className="a-modal">
      <div className="a-modal__content">
        <div className="a-modal__header">
          <h3 className="a-modal__title">{title}</h3>
          <Button
            text={closeLabel || "Close"}
            startIcon={<Icon name={IconName.Close} />}
            onClick={onClose}
            type="link"
          />
        </div>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
}

export default Modal;
