import Select from "shared/Select";
import { TOption } from "shared/Select/Select";

type TMonthRangeSelectorProps = {
  fromOptions: TOption[];
  toOptions: TOption[];
  fromValue: TOption | null;
  toValue: TOption | null;
  onFromChange: (option: TOption) => void;
  onToChange: (option: TOption) => void;
};

function MonthRangeSelector({
  fromOptions,
  toOptions,
  fromValue,
  toValue,
  onFromChange,
  onToChange,
}: TMonthRangeSelectorProps) {
  return (
    <div className="m-month-range">
      <Select
        value={fromValue}
        onChange={(value) => onFromChange(value as TOption)}
        isNaked
        isSearchable={false}
        options={fromOptions}
      />
      <span className="m-month-range__to">to</span>
      <Select
        value={toValue}
        onChange={(value) => onToChange(value as TOption)}
        isNaked
        isSearchable={false}
        options={toOptions}
      />
    </div>
  );
}

export default MonthRangeSelector;
