type TPillProps = {
  text: string;
  onClick?: () => void;
  color?: "white" | "dark";
  isActive?: boolean;
};

const BASE_CLASS = "a-pill";

const COLOR_CLASSNAMES = {
  white: `${BASE_CLASS}--white`,
  dark: `${BASE_CLASS}--dark`,
};

function Pill({ text, onClick, color = "white", isActive }: TPillProps) {
  return (
    <div
      onClick={onClick}
      className={`${BASE_CLASS} ${COLOR_CLASSNAMES[color]} ${
        onClick ? `${BASE_CLASS}--clickable` : ""
      } ${isActive ? `${BASE_CLASS}--active` : ""}`}
    >
      {text}
    </div>
  );
}

export default Pill;
