import { useExperienceBuilderContext } from "components/ExperienceBuilderEG/context/ExperienceBuilderContext";
import DOMPurify from "dompurify";
import { useMemo, useState } from "react";
import { Button, Checkbox, Loader } from "shared";
import { ButtonVariants } from "shared/Button/Button";
import Select, { TOption } from "shared/Select/Select";
import TextInput from "shared/TextInput";
import * as R from "ramda";

export type TYourDetails = {
  golfingAssociation: string;
  title: TOption | null;
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  isSubscriber: boolean;
};

const validateEmail = (email: string) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

function YourDetails() {
  const {
    labels,
    setActiveStep,
    yourDetails,
    onYourDetailsSubmit,
    selectedCourses,
    selectedHotels,
    selectedTours,
    isSubmitLoading,
    submitError,
  } = useExperienceBuilderContext();
  const [formValues, setFormValues] = useState<TYourDetails>(yourDetails);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const validateForm = (newFormValues?: TYourDetails) => {
    let errors: Record<string, string> = {};
    const formValuesToCheck = newFormValues ?? formValues;

    if (!formValuesToCheck.firstName.length) {
      errors.firstName = labels.YourDetails.FirstNameErrorMessage;
    }

    if (!formValuesToCheck.lastName.length) {
      errors.lastName = labels.YourDetails.LastNameErrorMessage;
    }

    if (
      !formValuesToCheck.email.length ||
      !validateEmail(formValuesToCheck.email)
    ) {
      errors.email = labels.YourDetails.EmailErrorMessage;
    }

    if (!formValuesToCheck.countryCode) {
      errors.countryCode = labels.YourDetails.CountryCodeErrorMessage;
    }

    if (!formValuesToCheck.phoneNumber) {
      errors.phoneNumber = labels.YourDetails.PhoneNumberErrorMessage;
    }

    setFormErrors(errors);

    return Object.keys(errors).length > 0;
  };

  const onSubmit = () => {
    const validationResult = validateForm();

    if (!validationResult) {
      onYourDetailsSubmit(formValues);
    }
  };

  return (
    <>
      <div className="u-wrapper m-your-details">
        {isSubmitLoading ? (
          <div
            style={{
              height: "600px",
              maxHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gridColumn: "1 / -1",
            }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="m-your-details__left">
              <Button
                extraClass="back-btn"
                text="Back"
                variant={ButtonVariants.Naked}
                onClick={() => setActiveStep("transport")}
              />
              <h2 className="a-title__main">{labels.YourDetails.Title}</h2>

              <div className="m-image-with-text__pill-wrap">
                <div className="m-image-with-text__pill">
                  <span>
                    {selectedCourses.length}{" "}
                    {labels.YourDetails.SelectedCourses}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: labels.YourDetails.CourseIcon,
                    }}
                  ></span>
                </div>
                <div className="m-image-with-text__pill">
                  <span>
                    {selectedHotels.length} {labels.YourDetails.SelectedHotels}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: labels.YourDetails.HotelIcon,
                    }}
                  ></span>
                </div>
                <div className="m-image-with-text__pill">
                  <span>
                    {selectedTours.length} {labels.YourDetails.SelectedTours}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: labels.YourDetails.TourIcon,
                    }}
                  ></span>
                </div>
              </div>

              <div className="a-title__short-text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(labels.YourDetails.Description),
                  }}
                ></p>
              </div>
            </div>
            <div className="m-your-details__right">
              <div className="form-heading">{labels.YourDetails.FormTitle}</div>
              <div className="m-your-details__right-form">
                <TextInput
                  placeholder={labels.YourDetails.FirstNamePlaceholder}
                  value={formValues.firstName}
                  onChange={(value) => {
                    if (value) {
                      setFormErrors((formErrors) =>
                        R.omit(["firstName"], formErrors)
                      );
                    }

                    setFormValues((values) => ({
                      ...values,
                      firstName: value,
                    }));
                  }}
                  error={formErrors.firstName}
                />
                <TextInput
                  placeholder={labels.YourDetails.LastNamePlaceholder}
                  value={formValues.lastName}
                  onChange={(value) => {
                    if (value) {
                      setFormErrors((formErrors) =>
                        R.omit(["lastName"], formErrors)
                      );
                    }

                    setFormValues((values) => ({
                      ...values,
                      lastName: value,
                    }));
                  }}
                  error={formErrors.lastName}
                />
                <TextInput
                  placeholder={labels.YourDetails.EmailPlaceholder}
                  value={formValues.email}
                  onChange={(value) => {
                    if (value && validateEmail(value)) {
                      setFormErrors((formErrors) =>
                        R.omit(["email"], formErrors)
                      );
                    }

                    setFormValues((values) => ({
                      ...values,
                      email: value,
                    }));
                  }}
                  type="email"
                  error={formErrors.email}
                />
                <TextInput
                  placeholder={labels.YourDetails.CountryDialCodePlaceholder}
                  value={formValues.countryCode}
                  onChange={(value) => {
                    if (value) {
                      setFormErrors((formErrors) =>
                        R.omit(["countryCode"], formErrors)
                      );
                    }

                    setFormValues((values) => ({
                      ...values,
                      countryCode: value,
                    }));
                  }}
                  error={formErrors.countryCode}
                  className="country-dial-code"
                />
                <TextInput
                  placeholder={labels.YourDetails.PhoneNumberPlaceholder}
                  value={formValues.phoneNumber}
                  error={formErrors.phoneNumber}
                  onChange={(value) => {
                    if (value) {
                      setFormErrors((formErrors) =>
                        R.omit(["phoneNumber"], formErrors)
                      );
                    }

                    setFormValues((values) => ({
                      ...values,
                      phoneNumber: value,
                    }));
                  }}
                  type="number"
                />
              </div>
              <div className="m-your-details__subscribe">
                <div>
                  <span>Subscribe to our newsletter</span>
                  <i className="zmdi zmdi-email"></i>
                </div>
                <Checkbox
                  id="subscribe"
                  name="subscribe"
                  checked={Boolean(formValues.isSubscriber)}
                  onChange={(value) => {
                    setFormValues((values) => ({
                      ...values,
                      isSubscriber: value,
                    }));
                  }}
                  label={labels.YourDetails.SubscribingText}
                />
              </div>

              {submitError && (
                <span className="submit-error">{submitError}</span>
              )}

              <Button
                text={labels.Common.SubmitBtn}
                onClick={onSubmit}
                extraClass="your-details-submit-btn"
                endIcon={<i className="zmdi zmdi-long-arrow-right"></i>}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    labels.YourDetails.PrivacyPolicyText
                  ),
                }}
              />
            </div>
          </>
        )}
      </div>
      <hr className="u-divider" />
    </>
  );
}

export default YourDetails;
