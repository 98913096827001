import Select from "shared/Select";
import { PAGINATION_OPTIONS } from "./Pagination.constants";
import { useMemo } from "react";
import * as R from "ramda";
import { TOption } from "shared/Select/Select";

type TPaginationSizeProps = {
  startText?: string;
  endText?: string;
  value: number;
  onChange: (value: number) => void;
};

function PaginationSize({
  startText,
  endText,
  value,
  onChange,
}: TPaginationSizeProps) {
  const selectedOption = useMemo(
    () => R.find((option) => option.value === value, PAGINATION_OPTIONS),
    [value]
  );

  return (
    <div className="m-pagination__size">
      {startText && <span>{startText}</span>}
      <Select
        value={selectedOption}
        options={PAGINATION_OPTIONS}
        onChange={(option) => onChange((option as TOption).value as number)}
        isSearchable={false}
      />
      {startText && <span>{endText}</span>}
    </div>
  );
}

export default PaginationSize;
