export enum LoaderSizes {
  Default = "default",
  XSmall = "xSmall",
  Small = "Small",
}
type TLoaderProps = {
  size?: LoaderSizes;
  className?: string;
};

const sizeCSSClassName = {
  [LoaderSizes.Default]: "",
  [LoaderSizes.XSmall]: "a-spinner--tiny",
  [LoaderSizes.Small]: "a-spinner--small",
};

function Loader({ size = LoaderSizes.Default, className = "" }: TLoaderProps) {
  return <div className={`a-spinner ${sizeCSSClassName[size]} ${className}`} />;
}

export default Loader;
